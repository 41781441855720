import React from 'react'
import { Link } from 'gatsby'

import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styles from './about.module.css'

import GreenIcon from '../components/assets/icons/GreenCircle.svg'
import StationIcon from '../components/assets/icons/Station.png'
import goSolveIcon from '../components/assets/icons/goSolve.jpg'

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="About"
        description="The official website of Jarren Rocks. Designer of experiences and products."
      />

  
      <Container>
        <div className={styles.wrap}>
          <div>
          <div className={styles.spread}></div>
          </div>
          <div>
            <h1>Hi. I'm Jarren Rocks.</h1>
            <p>
              For over 10 years I've been professionally designing experiences and products. I have a
              keen eye for tech and design trends, and regularly create videos about these ideas on
              my{' '}
              <a
                href="https://www.youtube.com/channel/UCy2DlxCMf_1oE7SWuGWtJ0A"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                YouTube channel.
              </a>{' '}
              <br />
              <br />
              I'm obsessed with user experience, and actively work to make the world a better place
              by way of thoughtful design.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className={styles.wrapAboutBlocks}>
          <Link to="/climate" className={styles.aboutBlock + ' ' + styles.green}>
            <div>
              <img src={GreenIcon} className={styles.aboutBlockImage} alt="green plant icon"></img>
              <h3>Carbon Negative since 2021</h3>
              <p>
                Personal life, projects, and businesses are carbon offset by 110% yearly. <br></br>
                Sustainable resources used whenever possible.
              </p>
            </div>
          </Link>

          <div className={styles.aboutBlock}>
            <div>
              <img src={StationIcon} className={styles.aboutBlockImage} alt="The Station Logo"></img>
              <h3>The Station</h3>
              <p>
                A community for the curious.<br></br>Link coming soon.
              </p>
            </div>
          </div>

          <a 
          href="https://www.gosolve.org"
          target="_blank"
          rel="noopener noreferrer"
          
          className={styles.aboutBlock}>
            <div>
              <img src={goSolveIcon} className={styles.aboutBlockImage} alt="goSolve Logo"></img>
              <h3>goSolve</h3>
              <p>
                A social network dedicated to solving global problems.
              </p>
            </div>
          </a>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutPage
